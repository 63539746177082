<template>
  <div class="returnBckData">
    <i class="iconfont icon-fanhui" @click="$router.go(-1)"></i>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .returnBckData{
    position: fixed;
    top: 20px;
    left: 15px;
    z-index: 999;
    width: 40px;
    height: 40px;
    // background: rgba(0,0,0,0.5);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    i{
      font-size: 20px;
    }
  }
</style>