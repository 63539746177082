
let date = value=>{
    var timeDifference=Date.now()-(value*1000); //时间差
    var hour = parseInt(timeDifference/1000/60/60); //小时
    var mini = parseInt((timeDifference/1000/60)%60);  //分钟  
    // var second = parseInt((timeDifference/1000)%(60)); 
    if(hour<=0){
        return mini+"分钟前";
    }else{
        if(0<hour&&hour<24){
            return hour+"小时前";
        }else if(24<=hour&&hour<48){
            return "1天前";
        }
    }

    let date = new Date(value*1000);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    // let h = date.getHours();
    // h = h < 10 ? ('0' + h) : h;
    // let m = date.getMinutes();
    // m = m < 10 ? ('0' + m) : m;
    // let s = date.getSeconds();
    // s = s < 10 ? ('0' + s) : s;
    return y + '年' + MM + '月' + d + '日';
}

let dateTwo = value=>{
    var timeDifference=Date.now()-value; //时间差
    var hour = parseInt(timeDifference/1000/60/60); //小时
    var mini = parseInt((timeDifference/1000/60)%60);  //分钟  
    // var second = parseInt((timeDifference/1000)%(60)); 
    if(hour<=0){
        return mini+"分钟前";
    }else{
        if(0<hour&&hour<24){
            return hour+"小时前";
        }else if(24<=hour&&hour<48){
            return "1天前";
        }
    }

    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    // let h = date.getHours();
    // h = h < 10 ? ('0' + h) : h;
    // let m = date.getMinutes();
    // m = m < 10 ? ('0' + m) : m;
    // let s = date.getSeconds();
    // s = s < 10 ? ('0' + s) : s;
    return y + '年' + MM + '月' + d + '日';
}

let dateThree = value=>{
    var timeDifference=Date.now()-(value*1000); //时间差
    var hour = parseInt(timeDifference/1000/60/60); //小时
    var mini = parseInt((timeDifference/1000/60)%60);  //分钟  
    // var second = parseInt((timeDifference/1000)%(60)); 
    if(hour<=0){
        return mini+"分钟前";
    }else{
        if(0<hour&&hour<24){
            return hour+"小时前";
        }else if(24<=hour&&hour<48){
            return "1天前";
        }
    }

    let date = new Date(value*1000);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();
    m = m < 10 ? ('0' + m) : m;
    // let s = date.getSeconds();
    // s = s < 10 ? ('0' + s) : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m;
}


module.exports={
    date,
    dateTwo,
    dateThree
}

// export {date}