import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
export default {
  install(Vue) {
    Vue.prototype.judgetPhone = function () {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      if (isAndroid) {
        return 1;
        //安卓
      } else {
        return 2;
      }
    },
      // 返回上一层
      Vue.prototype.goBackPage = function (router) {
        router.back(-1);
      },
      // IOS交互
      Vue.prototype.setupWebViewJavascriptBridge = function (callback) {
        if (window.WebViewJavascriptBridge) {
          return callback(window.WebViewJavascriptBridge);
        }
        if (window.WVJBCallbacks) {
          return window.WVJBCallbacks.push(callback);
        }
        window.WVJBCallbacks = [callback];
        var WVJBIframe = document.createElement("iframe");
        WVJBIframe.style.display = "none";
        WVJBIframe.src = "https://__bridge_loaded__";
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(function () {
          document.documentElement.removeChild(WVJBIframe);
        }, 0);
      },
      // 判断是否登录
      Vue.prototype.isToken = function () {
        let token = window.localStorage.userinfo ? JSON.parse(window.localStorage.userinfo).token : false
        if (token) {
          return true
        } else if (window.localStorage.enterpriseInfo && window.localStorage.enterpriseInfo != 'undefined') {
          Toast('企业不能操作当前模块，请切换身份！');
          return false;
        } else {
          this.$router.push('/login');
          return false
        }
      },
      // 返回上一级
      Vue.prototype.returnPage = function () {
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        if (isAndroid) {  //安卓
          window.javaInterface.goBack();
        } else { //苹果
          this.setupWebViewJavascriptBridge(function (bridge) {
            bridge.registerHandler("testJavascriptHandler", function (data, responseCallback) { //注册
              var responseData = { "Javascript Says": "Right back atcha!" };
              responseCallback(responseData);
            });
            bridge.callHandler("goBack", {}, function (response) {
              console.log(response);
            });
          });
        }
      },
      // 跳到分享
      Vue.prototype.shareContent = function (sendData) {
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        if (isAndroid) {  //安卓
          window.javaInterface.shareContent(JSON.stringify(sendData));
        } else { //苹果
          this.setupWebViewJavascriptBridge(function (bridge) {
            bridge.registerHandler("testJavascriptHandler", function (data, responseCallback) { //注册
              var responseData = { "Javascript Says": "Right back atcha!" };
              responseCallback(responseData);
            });
            bridge.callHandler("shareContent", sendData, function (response) {
              console.log(response);
            });
          });
        }
      }
    // 交互统一方法
    Vue.prototype.currency = function (title, sendData) {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      if (isAndroid) {  //安卓
        if (sendData) {
          window.javaInterface[title](sendData);
        } else {
          window.javaInterface[title]();
        }
      } else { //苹果
        this.setupWebViewJavascriptBridge(function (bridge) {
          bridge.registerHandler("testJavascriptHandler", function (data, responseCallback) { //注册
            var responseData = { "Javascript Says": "Right back atcha!" };
            responseCallback(responseData);
          });
          bridge.callHandler(title, sendData, function (response) {
            console.log(response);
          });
        });
      }
    },
      //判断什么端打开
      Vue.prototype.os = function () {
        var ua = navigator.userAgent,
          isWindowsPhone = /(?:Windows Phone)/.test(ua),
          isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
          isAndroid = /(?:Android)/.test(ua),
          isFireFox = /(?:Firefox)/.test(ua),
          isChrome = /(?:Chrome|CriOS)/.test(ua),
          isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
          isPhone = /(?:iPhone)/.test(ua) && !isTablet,
          isPc = !isPhone && !isAndroid && !isSymbian;
        return {
          isTablet: isTablet,
          isPhone: isPhone,
          isAndroid: isAndroid,
          isPc: isPc
        }
      }
  }
}
