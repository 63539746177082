<template>
  <div class="tabbar">
    <ul>
      <li
        v-for="(item, index) in tabList"
        :key="index"
        @click="onTab(item,index)"
        :class="{'choosed':index==nowindex}"
      >
        <img v-if="index==nowindex" :src="item.icoed" alt class="icons" />
        <img v-else :src="item.ico" alt class="icons" />
        <span>{{item.tabName}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'tabbar',
  data() {
    return {
      tabList: [
        {
          tabName: '首页',
          ico: require('../assets/tobbar/shouye.png'),
          icoed: require('../assets/tobbar/shouye1.png'),
          path: '/home'
        },
        {
          tabName: '点单',
          ico: require('../assets/tobbar/diandan.png'),
          icoed: require('../assets/tobbar/diandan1.png'),
          path: '/massorder'
        },
        {
          tabName: '活动',
          ico: require('../assets/tobbar/daka.png'),
          icoed: require('../assets/tobbar/daka1.png'),
          path: '/punchtheclock'
        },
        {
          tabName: '排行',
          ico: require('../assets/tobbar/paihang.png'),
          icoed: require('../assets/tobbar/paihang1.png'),
          path: '/paihangbang'
        },
        {
          tabName: '我的',
          ico: require('../assets/tobbar/wode.png'),
          icoed: require('../assets/tobbar/wode1.png'),
          path: '/myInfo'
        }
      ],
      nowindex: 0
    }
  },
  // created() {
  //   this.suodindaohang();
  // },
  // // 当页面出现如果是爱心企业登入则清空useinfo
  // activated() {
  //   this.suodindaohang();
  // },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.suodindaohang();
    })
  },
  methods: {
    onTab(val, index) {
      if (index !== this.nowindex) {
        this.$router.push({ path: val.path });
        this.nowindex = index; // 锁定到当前的位置
      }
    },
    // 锁定到导航上面
    suodindaohang() {
      var noRou = window.location.href.split('/#')[1];
      for (var i = 0; i < this.tabList.length; i++) {
        if (noRou == this.tabList[i].path) {
          this.nowindex = i;
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.tabbar {
  width: 100%;
  height: 0.9rem;
  // padding: 10px 0 6px 0;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 999;
  box-sizing: border-box;
  ul {
    display: flex;
    height: 90%;
    li {
      flex: 1;
      // text-align: center;
      // line-height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0.2rem 0 0.1rem 0;
      .icons {
        max-height: 90%;
      }
      span {
        font-size: 0.2rem;
        // margin-top: 5px;
      }
    }
    .choosed {
      color: #1377e2;
    }
  }
}
</style>
