<template>
  <div class="headTitle" ref="headTitle">
    <div class="icons">
      <span @click="goBack" v-if="returnTop">
        <van-icon name="arrow-left" color="#000" class />
      </span>
    </div>
    <div ref="titlename" class="titlename">
      <span>{{title}}</span>
    </div>
    <div class="icons rightIcon">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getWidth()
  },
  props: {
    returnTop: { //返回上一层
      type: Boolean,
      default: () => true, //必须是一个函数
      required: false,
    },
    title: { // 当前名称
      type: String,
      default: () => '', //必须是一个函数
      required: false,
    },
    go: {
      type: String,
      default: () => '', //必须是一个函数
      required: false,
    }
  },
  created() {
    // alert(this.go)
  },
  methods: {
    goBack() {
      if (this.go == 'uni') {
        window.uni.navigateTo({ url: '/pages/tabbar/civilizedpractice/civilizedpractice' })
      } else {
        this.$router.go(-1)
      }
    },
    getWidth() {
      const span_Width = this.$refs.titlename.clientWidth;
      const head_Width = this.$refs.headTitle.clientWidth;
      console.log('s', this.span_Width);
      console.log('h', this.head_Width);
    }
  },
}
</script>

<style lang="less" scoped>
.headTitle {
  width: 100%;
  height: 44px;
  position: fixed;
  top: 0px;
  left: 0px;
  background: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  box-sizing: border-box;
  font-size: 18px;
  z-index: 999;
  .icons {
    width: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .rightIcon {
    justify-content: flex-end;
  }
  .titlename {
    width: calc(100%-130px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}
</style>