import Vue from 'vue'
import Router from 'vue-router'
import Tabbar from '../components/tobbar'

Vue.use(Router)

export default new Router({
  routes: [{
    path: '/home', //首页
    name: 'home',
    components: {
      default: () => import('@/components/czComponents/HomePage'),
      tabbar: Tabbar
    },
    meta: {
      keepAlive: true
    }
  },
  { // 点单模块
    path: '/massorder', //群众点单
    name: 'massorder',
    components: {
      default: () => import('@/components/massorder/order.vue'),
      tabbar: Tabbar
    },
    meta: {
      keepAlive: true
    }
  },{ // 点单模块(供五单派送使用)
    path: '/massorderTwo', //群众点单
    name: 'massorderTwo',
    components: {
      default: () => import('@/components/massorder/order.vue')
    }
  }, {
    path: '/callbackDetail', //点单详情
    name: 'callbackDetail',
    components: {
      default: () => import('@/components/massorder/Detail.vue'),
    }
  },

  { // 打卡模块
    path: '/punchtheclock', // 打卡首页
    name: 'punchtheclock',
    components: {
      default: () => import('@/components/cllComponents/punchtheclock/active'),
      tabbar: Tabbar
    },
    meta: {
      keepAlive: true
    }
  }, { // 打卡模块
    path: '/punchtheclockFive', // 打卡页(供五单派送使用)
    name: 'punchtheclockFive',
    components: {
      default: () => import('@/components/cllComponents/punchtheclock/active'),
    }
  },{
    path: '/activeDetail', // 打卡活动详情 
    name: 'activeDetail',
    components: {
      default: () =>
        import('@/components/cllComponents/punchtheclock/activeDetail'),
    }
  }, {
    path: '/activeDetailTwo', // 打卡活动详情 
    name: 'activeDetailTwo',
    components: {
      default: () =>
        import('@/components/cllComponents/punchtheclock/activeDetailTwo'),
    },
  },
  // { //考勤打卡
  //   path: '/punchtheclockcontent', // 打卡子页 
  //   name: 'punchtheclockcontent',
  //   components: {
  //     default: () =>
  //       import('@/components/cllComponents/punchtheclock/punchtheclockcontent'),
  //   },
  //   meta: {
  //     keepAlive: false
  //   }
  // },


  { // 活动招募
    path: '/voluntaryRecruitment', // 志愿者报名
    name: 'voluntaryRecruitment',
    components: {
      default: () => import('@/components/fhComponents/voluntaryRecruitment')
    },
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/voluntaryRecruitmentDetail', // 志愿者报名详情页面
    name: 'voluntaryRecruitmentDetail',
    components: {
      default: () => import('@/components/fhComponents/voluntaryRecruitmentDetail')
    }
  },

  { //排行榜
    path: '/paihangbang',
    name: 'paihangbang',
    components: {
      default: () => import('@/components/zkjComponets/paihangbang.vue'),
      tabbar: Tabbar
    },
    meta: {
      keepAlive: false
    }
  },


  {
    path: '/', //空白页
    name: 'blank',
    components: {
      default: () =>
        import('../components/blank'),
      tabbar: Tabbar
    },
    meta: {
      keepAlive: true
    }
  },


  {
    path: '/army', //队伍建设
    name: 'army',
    components: {
      default: () => import('@/components/czComponents/Army.vue'),
    }
  }, {
    path: '/armyDetail', //队伍建设详情页
    name: 'armyDetail',
    components: {
      default: () => import('@/components/czComponents/ArmyDetail.vue'),
    }
  }, {
    path: '/teamList', // 团队列表
    name: 'teamList',
    components: {
      default: () => import('@/components/czComponents/TeamList.vue'),
    },
    meta: {
      keepAlive: true
    }
  }, {
    path: '/myteam', // 我加入的团队
    name: 'myteam',
    components: {
      default: () => import('@/components/czComponents/MyJoinTeam.vue'),
    },
    meta: {
      keepAlive: true
    }
  }, {
    path: '/myactivity', // 我负责的活动
    name: 'myactivity',
    components: {
      default: () => import('@/components/czComponents/MyResponsibleActivity.vue'),
    },
    meta: {
      keepAlive: true
    }
  }, {
    path: '/wish', //志愿典型
    name: 'wish',
    components: {
      default: () => import('@/components/czComponents/Wish.vue'),
    },
    meta: {
      keepAlive: true
    }
  },

  {
    path: '/detailsTwo', //详情页
    name: 'detailsTwo',
    components: {
      default: () =>
        import('../components/contentDetailCivilization'),
    }
  },
  {
    path: '/wxShare_contentDetail', //微信分文章详情页
    name: 'wxShare_contentDetail',
    components: {
      default: () =>
        import('../components/wxShare/wxShare_contentDetail.vue'),
    }
  },
  {
    path: '/wxShare_voluntaryRec', //微信分享志愿者活动
    name: 'wxShare_voluntaryRec',
    components: {
      default: () =>
        import('../components/wxShare/wxShare_voluntaryRec.vue'),
    }
  },
  {
    path: '/tabloid', //详情页
    name: 'tabloid',
    components: {
      default: () =>
        import('../components/tabloid'),
    }
  }, {
    path: '/sharePage', //分享页
    name: 'sharePage',
    components: {
      default: () =>
        import('../components/sharePage')
    }
  }, {
    path: '/sharePageTwo', //分享页
    name: 'sharePageTwo',
    components: {
      default: () => import('../components/sharePageTwo')
    }
  }, {
    path: '/Integralrule', //分享页
    name: 'Integralrule',
    components: {
      default: () => import('../components/Integralrule.vue'),
    }
  }, {
    path: '/Mediareport', //媒体报道
    name: 'Mediareport',
    components: {
      default: () => import('../components/czComponents/Mediareport'),
    },
    meta: {
      keepAlive: true
    }

  }, {
    path: '/searchPage', //搜索
    name: 'searchPage',
    components: {
      default: () =>
        import('../components/searchPage')
    },
    meta: {
      keepAlive: true
    }
  },


  {
    path: '/demand', //需求
    name: 'demand',
    components: {
      default: () => import('@/components/wish/demand.vue')
    },
    meta: {
      keepAlive: true
    }
  }, {
    path: '/detail', //需求
    name: 'detail',
    components: {
      default: () => import('@/components/wish/Detail.vue'),
    }
  }, {
    path: '/wishList', //群众点单
    name: 'wishList',
    components: {
      default: () => import('@/components/wish/order.vue'),
    }
  }, {
    path: '/user', //个人中心
    name: 'user',
    components: {
      default: () =>
        import('@/components/user/User')
    }
  }, {
    path: '/signUser', //个人注册中心
    name: 'signUser',
    components: {
      default: () =>
        import('@/components/user/signUser')
    }
  }, {
    path: '/mywish', //我的心愿
    name: 'mywish',
    components: {
      default: () =>
        import('@/components/user/MyWish'),
    },
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/scanCodeDetail', // 活动扫码详情
    name: 'ScanCodeDetail',
    components: {
      default: () =>
        import('@/components/user/ScanCodeDetail'),
    }
  },
  {
    path: '/userQRCode', // 活动扫码详情
    name: 'UserQRCode',
    components: {
      default: () =>
        import('@/components/user/UserQRCode'),
    }
  }, {
    path: '/login', //登录
    name: 'login',
    components: {
      default: () =>
        import('@/components/user/Login'),
    }
  }, {
    path: '/register', //注册 
    name: 'register',
    components: {
      default: () =>
        import('@/components/user/Register'),
    }
  }, {
    path: '/find', //找回密码
    name: 'findpass',
    components: {
      default: () =>
        import('@/components/user/FindPass'),
    }
  }, {
    path: '/apply', // 爱心企业或个人申请 相当于爱心企业注册
    name: 'apply',
    components: {
      default: () => import('@/components/user/ApplyToBeLove.vue'),
    }
  }, {
    path: '/armyintroduce', //组织介绍
    name: 'armyintroduce',
    components: {
      default: () => import('@/components/czComponents/Armyintroduce.vue'),
    }
  },



  {
    path: '/interaction', // 爆料
    name: 'Interaction',
    components: {
      default: () =>
        import('../components/interaction/Index')
    },
    meta: {
      keepAlive: true
    }
  }, {
    path: '/classPage', // 爆料分类页面
    name: 'ClassPage',
    components: {
      default: () =>
        import('../components/interaction/ClassPage'),
    }
  }, {
    path: '/interactionDetail', // 爆料详情·页面
    name: 'InteractionDetail',
    components: {
      default: () =>
        import('../components/interaction/Detail'),
    }
  }, {
    path: '/interactionShare', // 爆料详情·页面
    name: 'InteractionShare',
    components: {
      default: () =>
        import('../components/interaction/Share'),
    }
  }, {
    path: '/publish', //上传
    name: 'Publish',
    components: {
      default: () => import('../components/interaction/Publish')
    }
  },

  {
    path: '/myinfo', // 我的页面
    name: 'MyInfo',
    components: {
      default: () => import('../components/user/MyInfo'),
      tabbar: Tabbar
    }, meta: {
      keepAlive: false
    }
  }, {
    path: '/mybook', // 证书页面
    name: 'MyBook',
    components: {
      default: () => import('../components/user/MyBook'),
    }
  }, {
    path: '/addbook', // 添加证书
    name: 'AddBook',
    components: {
      default: () => import('../components/user/AddBook'),
    }
  }, {
    path: '/myscore', // 我的积分
    name: 'MyScore',
    components: {
      default: () => import('../components/user/MyScore'),
    }
  }, {
    path: '/mytime', // 我的志愿时长
    name: 'MyTime',
    components: {
      default: () => import('../components/user/MyTime'),
    }
  },
  {
    path: '/cardPage', // 我的志愿时长
    name: 'cardPage',
    components: {
      default: () => import('../components/user/cardPage'),
    }
  },
  {
    path: '/modify', //修改密码
    name: 'Modify',
    components: {
      default: () =>
        import('@/components/user/ModifyPass'),
    }
  },
  {
    path: '/bookDetail', //证书详情
    name: 'BookDetail',
    components: {
      default: () =>
        import('@/components/user/BookDetail'),
    }
  },
  {
    path: '/articleNoNav', // 无导航文章
    name: 'ArticleNoNav',
    components: {
      default: () =>
        import('@/components/czComponents/ArticleNoNav'),
    }
  },
  {
    path: '/articleWithNav', // 有导航文章
    name: 'ArticleWithNav',
    components: {
      default: () =>
        import('@/components/czComponents/ArticleWithNav'),
    },
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/fiveOrder', // 五单派送
    name: 'FiveOrder',
    components: {
      default: () =>
        import('@/components/fiveOrder/Index'),
    }
  },
  {
    path: '/centerOrder', // 五单派送中心制单
    name: 'CenterOrder',
    components: {
      default: () =>
        import('@/components/fiveOrder/centerOrder'),
    }
  }
  ]
})