import request from '@/utils/request'
export function login(data) {
  return request({
    url: '/v2/api/ad_user/auth/login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/v2/api/ad_user/auth/verify',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/v2/api/ad_user/auth/logout',
    method: 'post'
  })
}

// token续期
export function refreshtoken() {
  return request({
    url: '/v2/api/app/ap_member/auth/refresh_token',
    method: 'get'
  })
}

// 获取用户信息
export function obtainUseInfor(params) {
  return request({
    url: '/v2/api/app/ap_member/member/query_info',
    method: 'get',
    params
  })
}

// 企业注册接口
export function wishmemberAdd(data) {
  return request({
    url: '/v2/api/app/ap_member//wish/member/add',
    method: 'post',
    data
  })
}

// 手机号输入框失焦触发，用于验证申请人的注册情况
export function checkWishMemberIsTrueOther(params) {
  return request({
    url: '/v2/api/app/ap_member//wish/member/checkWishMemberIsTrueOther',
    method: 'get',
    params
  })
}

// 会员注册获取验证码
export function checkRegisterPhone(params) {
  return request({
    url: '/v2/api/app/ap_member//member/query/check/phone',
    method: 'get',
    params
  })
}


// 登入接口 爱心企业的登入
export function wishLogin(params) {
  return request({
    url: '/v2/api/app/ap_member//auth/wish/login',
    method: 'post',
    params
  })
}

// 登入接口 会员登入
export function merberLogin(data) {
  return request({
    url: '/v2/api/app/ap_member//auth/login',
    method: 'post',
    data
  })
}
//进入 积分同步
export function scoreAll(params, data) {
  return request({
    url: '/v2/api/app/ap_money//memberinfo/queryData',
    method: 'post',
    params,
    data
  })
}
// 获取验证码接口 爱心企业
export function obtainCode(params) {
  return request({
    url: '/v2/api/app/ap_member//auth/sendVerificationCode',
    method: 'get',
    params
  })
}

// 注册手机号验证
export function checkWishMemberIsTrue(params) {
  return request({
    url: '/v2/api/app/ap_member//wish/member/checkWishMemberIsTrue',
    method: 'get',
    params
  })
}

// 验证码验证
export function verificationCodeIsTrue(params) {
  return request({
    url: '/v2/api/app/ap_member//auth/verificationCodeIsTrue',
    method: 'get',
    params
  })
}

// 更改密码 爱心企业
export function wishMemberForgetPassword(params) {
  return request({
    url: '/v2/api/app/ap_member//wish/member/wishMemberForgetPassword',
    method: 'get',
    params
  })
}

// 忘记密码 会员
export function MemberForgetPassword(params) {
  return request({
    url: '/v2/api/app/ap_member//member/query/forgot/password',
    method: 'get',
    params
  })
}

// 爱心企业获取相对信息
export function wishInfomatin(params) {
  return request({
    url: '/v2/api/app/ap_member//wish/member/query/wish/info',
    method: 'get',
    params
  })
}
// 会员获取相对信息
export function memberInfomatin(params) {
  return request({
    url: '/v2/api/app/ap_member//member/query_info?memberId=&regionId=&id=',
    method: 'get',
  })
}

// 会员注册
export function memberRegister(data) {
  return request({
    url: '/v2/api/app/ap_member//member/add/register_account',
    method: 'post',
    data
  })
}

// 会员修改密码
export function memberModifyPass(data) {
  return request({
    url: '/v2/api/app/ap_member//member/update_password',
    method: 'post',
    data
  })
}

// 会员所有特长
export function getAllSpecial() {
  return request({
    url: '/v2/api/app/ad_user//special/query_all',
    method: 'get',
  })
}

// 修改会员信息
export function updateInfo(data) {
  return request({
    url: '/v2/api/app/ap_member//member/update_account',
    method: 'post',
    data
  })
}

export default {
  wishInfomatin, memberInfomatin, obtainUseInfor, wishmemberAdd, checkWishMemberIsTrueOther, checkRegisterPhone, wishLogin, merberLogin, obtainCode, checkWishMemberIsTrue, verificationCodeIsTrue,
  wishMemberForgetPassword,
  MemberForgetPassword,
  memberRegister,
  memberModifyPass,
  getAllSpecial,
  updateInfo,
  scoreAll
}
