<template>
  <div id="app">
    <keep-alive>
      <router-view class="routerContent" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 没有缓存 -->
    <router-view class="routerContent" v-if="!$route.meta.keepAlive"></router-view>
    <router-view name="tabbar"></router-view>
  </div>
</template>

<script>
import { Toast } from "vant";
import useApi from '@/api/auth'
export default {
  name: 'App',
  created() {
    document.title = this.GlobalteamName;
  },
  mounted() {
    window['getUserMessage'] = (callback) => {
      console.log(callback)
    }
  },
  methods: {}
}
</script>

<style>
/* @font-face {
		font-family:"微软雅宋"; 
		src: url("./assets/ttf/FZBIAOYSJW.TTF");
	} */
* {
  margin: 0;
  padding: 0;
}
a {
  color: #424242;
}
li {
  list-style: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

html {
  -webkit-overflow-scrolling: touch;
}
/* html {
		height: 100%;
	} */
body {
  /* max-width: 700px; */
  overflow-x: hidden;
  background: #f1f1f1;
  /* margin: 0 auto!important; */
}
body,
html {
  width: 100%;
  height: 100%;
}
#app {
  font-family: "微软雅宋";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.2rem;
  width: 100%;
  height: 100%;
  color: #333333;
  background: #fff;
  margin: 0px auto;
  /* max-width: 700px; */
}
.routerContent {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-bottom: 50px; */
  box-sizing: border-box;
}
.outerFrame {
  height: calc(100% - 0.5rem);
}
.titlesTop {
  padding-top: 44px;
  box-sizing: border-box;
}
</style>
