import axios from 'axios'
// import Vue from 'vue'
// import vue from '../main'
import router from '@/router'
import { Toast } from 'vant'
var urllink = 'https://gateway2.fst1994.net' // 线上接口
// var urllink = 'http://192.168.5.203:21020' // 测试接口
// var urllink = 'http://114.107.229.59:21020' // 测试接口
// var urllink = 'https://test1.fst1994.net' // 测试接口
// var urllink = 'http://192.168.1.178:21020' // 测试接口

const service = axios.create({
  baseURL: urllink,
  timeout: 60000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is se nt
    var timestamp = new Date().getTime()
    const Userinfo = window.localStorage.userinfo ? JSON.parse(window.localStorage.userinfo) : '' //用户信息有token ，refreshToken，expireTime
    var expireTime = Userinfo.expireTime
    if (Userinfo.token) {
      if (timestamp > expireTime) {
        config.headers['Authorization'] = Userinfo.refreshToken
        config.headers['X-Token'] = Userinfo.refreshToken
      } else {
        config.headers['Authorization'] = Userinfo.token
        config.headers['X-Token'] = Userinfo.token
      }
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      Toast.fail(response.message);
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    let code = 0
    try {
      code = error.response.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Toast.fail("网络请求超时");
        return Promise.reject(error)
      }
    }
    if (code) {
      if (code === 403) {
        Toast.fail("用户信息失效，请重新登入！");
        if (window.localStorage.userinfo) {
          window.localStorage.personalInformation = "";
          window.localStorage.userinfo = "";
          window.localStorage.enterpriseInfo = "";
          window.localStorage.oldWishToken = "";
          window.location.reload(); // 刷新页面
        }
      } else if (code === 401) {
        router.push({ path: '/401' })
      } else {
        const errorMsg = error.response.data.message
        if (errorMsg !== undefined) {
          Toast.fail(errorMsg);
        }
      }
    } else {
      Toast.fail("接口请求失败");
    }
    return Promise.reject(error)
  }
)

export default service
