import '@/assets/1rem100px.js'
import Event from '@/assets/eventVue.js'
import 'vant/lib/index.css'
import Vue from 'vue'
import phone from '../config/phone.js'
import App from './App.vue'
import './assets/iconfont/iconfont.css'
import router from './router'
// import '@/assets/pdf/viewer.js'
import { refreshtoken } from '@/api/auth'
import "animate.css"
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant);

import * as custom from './assets/globalFilters.js'
import './utils/components'

// 全局需要用到的数据
// Vue.prototype.appName = '南陵文明'
// Vue.prototype.GlobalteamName = '南陵县新时代文明实践中心'

// Vue.prototype.appName = '长丰云'
// Vue.prototype.GlobalteamName = '长丰县新时代文明实践中心'
Vue.prototype.appName = '爱旌德'
Vue.prototype.GlobalteamName = '旌德县新时代文明实践中心'
// Vue.prototype.appName = '智慧新洲'
// Vue.prototype.GlobalteamName = '新洲区新时代文明实践中心'

// Vue.prototype.appName = '石台文明'
// Vue.prototype.GlobalteamName = '石台新时代文明实践中心'
// Vue.prototype.scoreShopUrl = 'https://shitai.fst1994.net/mmall/#/'   // 有积分商城

// Vue.prototype.appName = '望江文明'
// Vue.prototype.GlobalteamName = '望江新时代文明实践中心'
// Vue.prototype.scoreShopUrl = 'https://wangjiang.fst1994.net/jifen/#/'      // 有积分商城

// Vue.prototype.appName = '金寨文明'
// Vue.prototype.GlobalteamName = '金寨县新时代文明实践中心'
// Vue.prototype.appName = "今日和县"
// Vue.prototype.GlobalteamName = '和县新时代文明实践中心'
// Vue.prototype.appName = '独秀怀宁'
// Vue.prototype.GlobalteamName = '怀宁县新时代文明实践中心'

// Vue.prototype.appName = '掌上绩溪'
// Vue.prototype.GlobalteamName = '绩溪县新时代文明实践中心'
// Vue.prototype.appName = '大圩文明'
// Vue.prototype.GlobalteamName = '大圩新时代文明实践中心'
// Vue.prototype.appName = '大美谯城'
// Vue.prototype.GlobalteamName = '谯城新时代文明实践中心'
// Vue.prototype.appName = '看埇桥'
// Vue.prototype.GlobalteamName = '埇桥新时代文明实践中心'

// Vue.prototype.appName = '我的泾县'
// Vue.prototype.GlobalteamName = '泾县新时代文明实践中心'

// Vue.prototype.appName = '涡阳文明'
// Vue.prototype.GlobalteamName = '涡阳县新时代文明实践中心'

// 主题色 // 图音影接口拼接头
Vue.prototype.styleBox = {
  backgroundColor: { //带背景字体
    background: '#1377e2',
    color: '#fff'
  },
  fontStyle: { //字体
    color: "#1377e2"
  },
  backOne: { //背景
    background: '#1377e2',
  },
  colorAll: "#1377e2",
}

Vue.use(phone);
Vue.prototype.Event = Event
// 导出的是对象，可以直接通过 key 和 value 来获得过滤器的名和过滤器的方法
Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key]);
})
// 滚动条缓存
router.beforeEach((to, from, next) => {
  const Userinfo = window.localStorage.userinfo ? JSON.parse(window.localStorage.userinfo) : ''
  if (Userinfo.token) {
    var timestamp = new Date().getTime()
    var expireTime = Userinfo.expireTime //获取缓存时间差
    if (timestamp > expireTime) {
      resetToken()
    }
  }

  var gundong = document.getElementsByClassName('gundong');
  var scrollTop = [];
  for (let i = 0; i < gundong.length; i++) {
    scrollTop.push(gundong[i].scrollTop);
  }
  if (window.localStorage.newposition) {
    window.localStorage.oldposition = window.localStorage.newposition;
  }
  window.localStorage.newposition = JSON.stringify({
    path: from.path,
    scrollTop: scrollTop
  });
  next();
})
router.afterEach((to, from) => {
  // 如果进入后的页面不想需要缓存，则设置scrollTop = 0
  setTimeout(() => {
    if (window.localStorage.oldposition) {
      var isgundongs = JSON.parse(window.localStorage.oldposition);
      var gundong = document.getElementsByClassName('gundong');
      if (to.path == isgundongs.path) {
        for (let j = 0; j < gundong.length; j++) {
          gundong[j].scrollTop = isgundongs.scrollTop[j];
        }
      }
    }
  }, 50)
})

// 将时间戳转日期格式的过滤器
Vue.filter('dateFormatOne', (dataStr) => {
  dataStr = dataStr.replace(/-/g, "/");
  var time = new Date(dataStr);
  function timeAdd0(str) {
    if (str < 10) {
      str = '0' + str;
    }
    return str
  }
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  // var h = time.getHours();
  // var mm = time.getMinutes();
  // var s = time.getSeconds();
  return y + '-' + timeAdd0(m) + '-' + timeAdd0(d);
})

Vue.filter('dateFormatTwo', (timestamp) => {
  var date = new Date(timestamp);
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  return Y + M + D + h + m + s;
})

export const resetToken = () => {
  refreshtoken().then(res => {
    const { data } = res
    const id = JSON.parse(window.localStorage.userinfo).id
    let params = {
      id: id,
      expireTime: data.expireTime,
      refreshToken: data.refreshToken,
      token: data.token
    }
    window.localStorage.userinfo = JSON.stringify(params);
    //localStorage.setItem("userinfo", params); 刷新页面
    // this.currency('updateUserinfo', JSON.stringify(params));
  })
}

var vue = new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

export default vue